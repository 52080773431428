const initialState = {
    CusInvoiceOnly: null,
    invoiceError: false,
    CusInvoiceOtherItem: null,
    InvoiceTypeOnly: "",
    invoiceId: null,
    InvoiceNo: "",
    InvoiceAddState: false,
    InvoiceDateDuePeriod: "",
    invoiceCusTaxOp: "",
    InvoiceOverviewData: [],
    InvoiceOverviewDataPageSize: 0,
    InvoiceOverviewTotal: 0,
    DeletedInvoiceOverviewData: [],
    DeletedInvoiceOverviewDataPageSize: 0,
    DeletedInvoiceOverviewTotal: 0,
    CusInvoiceBasicData: null,
    CusInvoiceLineOnly: null,
    CusInvoiceSurchargeOnly: null,
    getAccountdata: [],
    getAccountdataPNULL: "",
    addaccountdata: false,
    InvoiceUpdateState: false,
    CashSheduleData: "",
    CashSheduleLoading: false,
    CreditSheduleData: [],
    InvoiceCustomerDataOnly: "",
    InvoiceCustomerDataStatus: false,
    PaymentStatusState: false,
    PaymentStatusData: "",
    PaymentStatusUpdateState: false,
    cashSheduleStatusModel: false,
    CreditSheduleStatusModel: false,
    SetCashSheduleState: false,
    StatusData: null,
    StatusDataWithOutClosed: null,
    StatusHistoryData: null,
    StatusUpdateStatus: false,
    UserBlockedStatus: false,
    ChangePaymentModalStatus: false,
    ChangePaymentStatusLoading: false,
    GetCusRemainPayData: null,
    GetCusInvoiceRemainPayData: [],
    SalesCommisionData: null,
    SalesCommisionPageSize: 0,
    ItemQuantitiyData: null,

    ItemQuantitiyDataForOther: null,
    ItemQuantitiyDataPO: null,
    ItemQuantitiyDataALL: null,
    updateBankDataLoading: false,
    updatebankdata: false,
    deletebankstatus: false,
    addbankBranch: false,
    loadingBranchData: false,
    getbankbranchData: null,
    getbankbranchpageSize: 0,
    deleteBankBranchStatus: false,
    getPayMethodsforSup: null,
    SequenceModalState: false,
    InvoiceReportModalState: false,
    ProduceIteIds: [],
    itemWeightProduceItems: [],
    QuantityData: [],
    countdata: [],
    getCustomerOrderNumber: null,
    getCustomerOrderData: null,
    createCustomerorstatus: false,
    deleteCustomerOrder: false,
    itemAssignModal: false,
    getCustomerorderLineData: null,
    customerOrderdataToUpdate: null,
    pathToCustomerOrderUpdate: null,
    loadingCustomerOrderUpdate: false,
    CustomerOrderUpdateStatus: false,
    loadingOrderStatus: false,
    addOrderStatus: false,
    getOrderStatusData: null,
    getOrderStatusDatapageSize: 0,
    deleteorderStausdata: false,
    isDefaultID: null,
    changeDefaultOrderStatus: false,
    getDefaultorderdatastatus: null,
    storeManualEntryData: null,
    tempstoreManualEntryData: null,
    loadingManuaLEntryData: false,
    addManualEntryDataStatus: false,
    getParentAccountData: null,
    disableInVoiceUpdateBtn: false,
    InvoicePaymentDetails: [],
    TEMPitemWeightProduceItems: [],
    UpdateInvoiceCount: false,
    getAccountsInDetail: [],
    customerPayTerm:null,
    OverviewCodeLoadingStatus: false,
    OverviewCodeAddStatus: false,
    OverviewCodeUpdateStatus: false,
    loadingUpdateOverviewCode: false,
    deleteOverviewCodestatus: false,
    deleteChildOverviewCodestatus: false,
    getInvoiceOrdersInDetail: [],
    getInvoiceOrdersInSummeryDetail: [],
    getInvoiceOverviewDetail: [],
    ProformaInvoiceReportModalState: false,
    ProformaInvoicePrintData:null,
    invoiceImageUpdateState: false,
    getStatusReportInDetail: [],
    CusInvoiceTypeData: null,


};

const reducer = (state = initialState, action) => {
    const newstate = {
        ...state,
    };

    switch (action.type) {
        case "RESET_INVOICE":
            return {
                ...newstate,
                CusInvoiceOnly: null,
                CusInvoiceOtherItem: null,
                InvoiceTypeOnly: "",
                invoiceId: null,
                InvoiceNo: "",
                CusInvoiceBasicData: null,
                CusInvoiceLineOnly: null,
                InvoiceAddState: false,
                InvoiceDateDuePeriod: "",
                invoiceCusTaxOp: "",
                InvoiceUpdateState: false,
                InvoiceCustomerDataOnly: "",
                InvoiceCustomerDataStatus: false,
                UserBlockedStatus: false,
                ItemQuantitiyData: null,
                TEMPitemWeightProduceItems: [],
                ItemQuantitiyDataForOther: null,
                ItemQuantitiyDataPO: null,
                ItemQuantitiyDataALL: null,
                SequenceModalState: false,
                QuantityData: [],
                itemWeightProduceItems: [],
                ProduceIteIds: [],
                countdata: [],
                getCustomerOrderNumber: null,
                getCustomerOrderData: null,
                itemAssignModal: false,
                getCustomerorderLineData: null,
                customerOrderdataToUpdate: null,
                pathToCustomerOrderUpdate: null,
                ProformaInvoicePrintData: null,
            };

        case "GET_CUSTOMER_INVOICE_DATA_ONLY":
            return {
                ...newstate,
                CusInvoiceOnly: action.value,
            };

        case "SET_COMMON_INVOICE_ERROR":
            return {
                ...newstate,
                invoiceError: true,
            };
        case "END_COMMON_INVOICE_ERROR":
            return {
                ...newstate,
                invoiceError: false,
            };
        case "GET_CUSTOMER_INVOICE_OTHER_ITEM_ONLY":
            return {
                ...newstate,
                CusInvoiceOtherItem: action.value,
            };

        case "GET_INVOICE_TYPE_ONLY":
            return {
                ...newstate,
                InvoiceTypeOnly: action.value,
            };

        case "GET_INVOICE_DATE":
            return {
                ...newstate,
                InvoiceDateDuePeriod: action.value,
            };

        case "GET_INVOICE_CUS_TAX_OP":
            return {
                ...newstate,
                invoiceCusTaxOp: action.value,
            };
        case "RESET_INVOICE_CUS_TAX_OP":
            return {
                ...newstate,
                invoiceCusTaxOp: "",
            };
        case "GET_INVOICE_OVERVIEW_DATA":
            if (action.value.rows !== undefined) {
                return {
                    ...newstate,
                    InvoiceOverviewData: action.value.rows,
                    InvoiceOverviewDataPageSize: action.value.pages,
                    InvoiceOverviewTotal: action.value.count,
                };
            } else {
                return {
                    ...newstate,
                    InvoiceOverviewData: action.value,
                };
            }
        case "GET_DELETED_INVOICE_OVERVIEW_DATA":
            if (action.value.rows !== undefined) {
                return {
                    ...newstate,
                    DeletedInvoiceOverviewData: action.value.rows,
                    DeletedInvoiceOverviewDataPageSize: action.value.pages,
                    DeletedInvoiceOverviewTotal: action.value.count,
                };
            } else {
                return {
                    ...newstate,
                    DeletedInvoiceOverviewData: action.value,
                };
            }

        case "INVOICE_ADDED":
            return {
                ...newstate,
                invoiceId: action.value.invoiceID,
                InvoiceNo: action.value.invoiceNo,
                QuantityData: [],
                TEMPitemWeightProduceItems: [],
                ProduceIteIds: [],
                InvoiceAddState: true,
            };
        case "CHANGE_INVOICE_ADDED":
            return {
                ...newstate,
                InvoiceAddState: false,
            };

        case "INVOICE_ADD_FAIL":
            return {
                ...newstate,
                InvoiceAddState: false,
            };

        case "INVOICE_UPDATED_SUCCESSFULLY":
            return {
                ...newstate,
                InvoiceUpdateState: true,
                QuantityData: [],
                TEMPitemWeightProduceItems: [],
                itemWeightProduceItems: [],
                ItemQuantitiyData: null,
                ProduceIteIds: [],
            };
        case "CHANGE_INVOICE_UPDATE_STATUS":
            return {
                ...newstate,
                InvoiceUpdateState: false,
            };
        case "SET_INVOICE_BASIC_DATA":
            return {
                ...newstate,
                CusInvoiceBasicData: action.value,
            };
        case "SET_INVOICE_TYPE_DATA":
            return {
                ...newstate,
                CusInvoiceTypeData: action.value,
            };
        case "GET_CUSTOMER_INVOICE_LINE_DATA_ONLY":
            return {
                ...newstate,
                CusInvoiceLineOnly: action.value,
            };

        case "GET_CUSTOMER_INVOICE_SURCHARGE_DATA_ONLY":
            return {
                ...newstate,
                CusInvoiceSurchargeOnly: action.value,
            };
        case "GET_CUSTOMER_PAY_TERM":
            return {
                ...newstate,
                customerPayTerm: action.value,
            };
        case "GET_ACCOUNT_DATA":
            if (action.value.rows !== undefined) {
                return {
                    ...newstate,
                    getAccountdata: action.value.rows,
                    getAccountdatapageSize: action.value.pages,
                };
            } else {
                return {
                    ...newstate,
                    getAccountdata: action.value,
                    getAccountdataForAccounts: action.value,
                };
            }
        case "GET_ACCOUNT_DATA_LIST":
            return {
                ...newstate,
                getAccountDataList: action.value,
            };

        case "GET_ACCOUNTS_IN_DETAIL_WITH_PARAMS":
            return {
                ...newstate,
                getAccountsInDetailWithParams: action.value,
            };

        case "GET_ACCOUNT_DATA_FOR_SWIPT":
            return {
                ...newstate,

                getAccountdataForAccounts: action.value,
            };

        case "STORE_MANUAL_ENTRY_DATA":
            return {
                ...newstate,
                manualModal: !newstate.manualModal,
                storeManualEntryData: action.value,
            };

        case "LOADING_ADD_MANUAL_ENTRY_DATA":
            return {
                ...newstate,
                loadingManuaLEntryData: true,
            };
        case "ADD_MANUAL_ENTRY_DATA":
            return {
                ...newstate,
                loadingManuaLEntryData: false,
                addManualEntryDataStatus: true,
            };
        case "ADD_MANUAL_ENTRY_DATA_CHANGED":
            return {
                ...newstate,
                addManualEntryDataStatus: false,
                tempstoreManualEntryData: null,
            };
        case "ADD_MANUAL_ENTRY_DATA_FAILED":
            return {
                ...newstate,
                loadingManuaLEntryData: false,
                addManualEntryDataStatus: false,
            };

        case "STORE_MANUAL_ENTRY_TEMP":
            return {
                ...newstate,

                tempstoreManualEntryData: action.value,
            };

        case "GET_ACCOUNT_DATA_PNULL":
            return {
                ...newstate,
                getAccountdataPNULL: action.value,
            };

        case "ADD_ACCOUNT_DATA":
            return {
                ...newstate,
                addaccountdata: true,
            };

        case "GET_ACCOUNT_PARENT_DATA":
            return {
                ...newstate,
                getParentAccountData: action.value,
            };
        case "CUSTOMER_INVOICE_DELETED":
            return {
                ...newstate,
                InvoiceOverviewData: state.InvoiceOverviewData.filter((invoice) => invoice.id !== action.value),
            };
        case "GET_CASH_SHEDULE":
            return {
                ...newstate,
                CashSheduleData: action.value,
                CashSheduleLoading: false,
            };
        case "SET_GET_CASH_SHEDULE_LOADING":
            return {
                ...newstate,
                CashSheduleLoading: true,
            };

        case "GET_CREDIT_SHEDULE":
            return {
                ...newstate,
                CreditSheduleData: action.value,
            };
        case "GET_INVOICE_CUSTOMER_DATA_ONLY":
            return {
                ...newstate,
                InvoiceCustomerDataOnly: action.value,
                InvoiceCustomerDataStatus: true,
            };
        case "RESET_CUSTOMER_DATA_FOR_CASH_SHEDULE":
            return {
                ...newstate,
                InvoiceCustomerDataOnly: "",
                InvoiceCustomerDataStatus: false,
            };
        case "GET_INVOICE_CUSTOMER_DATA_CHANGE_STATE":
            return {
                ...newstate,
                InvoiceCustomerDataStatus: false,
            };
        case "SET_PAYMENT_STATUS_SUCCESS":
            return {
                ...newstate,
                PaymentStatusState: true,
            };
        case "CHANGE_PAYMENT_STATUS_STATE":
            return {
                ...newstate,
                PaymentStatusState: false,
            };
        case "SET_PAYMENT_STATUS_FAIL":
            return {
                ...newstate,
                PaymentStatusState: false,
            };
        case "GET_PAYMENT_STATUS_TYPE":
            return {
                ...newstate,
                PaymentStatusData: action.value,
            };

        case "PAYMENT_STATUS_DATA_UPDATED":
            return {
                ...newstate,
                PaymentStatusUpdateState: true,
            };
        case "CHANGE_PAYMENT_STATUS_DATA_STATE":
            return {
                ...newstate,
                PaymentStatusUpdateState: false,
            };
        case "UPDATE_PAYMENT_STATUS_DATA_UNSUCCESS":
            return {
                ...newstate,
                PaymentStatusUpdateState: false,
            };
        case "PAYMENT_STATUS_DATA_DELETED":
            return {
                ...newstate,
                PaymentStatusData: state.PaymentStatusData.filter((pStatus) => pStatus.id !== action.value),
            };
        case "CASH_SHEDULE_MODEL_STATE":
            return {
                ...newstate,
                cashSheduleStatusModel: !state.cashSheduleStatusModel,
            };

        case "SET_CASH_SHEDULE_STATUS_SUCCESS":
            return {
                ...newstate,
                SetCashSheduleState: true,
            };
        case "CHANGE_CASH_SHEDULE_STATUS_STATE":
            return {
                ...newstate,
                SetCashSheduleState: false,
            };
        case "SET_CASH_SHEDULE_STATUS_FAIL":
            return {
                ...newstate,
                SetCashSheduleState: false,
            };

        case "GET_STATUS_DATA":
            return {
                ...newstate,
                StatusData: action.value,
            };
        case "GET_STATUS_DATA_NO_CLOSED":
            return {
                ...newstate,
                StatusDataWithOutClosed: action.value,
            };

        case "GET_STATUS_HISTORY_DATA":
            return {
                ...newstate,
                StatusHistoryData: action.value,
            };

        case "STATUS_UPDATED":
            return {
                ...newstate,
                StatusUpdateStatus: true,
            };
        case "CHANGE_STATUS_UPDATED":
            return {
                ...newstate,
                StatusUpdateStatus: false,
            };
        case "STATUS_UPDATE_FAILED":
            return {
                ...newstate,
                StatusUpdateStatus: false,
            };

        case "USER_BLOCK_SUCCESS":
            return {
                ...newstate,
                UserBlockedStatus: true,
            };
        case "CHANGE_USER_BLOCK_STATE":
            return {
                ...newstate,
                UserBlockedStatus: false,
            };
        case "USER_BLOCK_FAIL":
            return {
                ...newstate,
                UserBlockedStatus: false,
            };

        case "GET_ACCOUNTS_IN_DETAIL":
            return {
                ...newstate,
                getAccountsInDetail: action.value,
            };
        case "CHANGE_ADD_ACCOUNT_DATA_STATUS":
            return {
                ...newstate,
                addaccountdata: false,
            };
        case "GET_CUS_REMAIN_PAY_DATA":
            return {
                ...newstate,
                GetCusRemainPayData: action.value,
            };

        case "RESET_GET_CUS_REMAIN_PAY_DATA":
            return {
                ...newstate,
                GetCusRemainPayData: null,
            };

        case "GET_CUS_INVOICE_REMAIN_PAY_DATA":
            return {
                ...newstate,
                GetCusInvoiceRemainPayData: action.value,
            };
        case "RESET_GET_CUS_INVOICE_REMAIN_PAY_DATA":
            return {
                ...newstate,
                GetCusInvoiceRemainPayData: [],
            };

        case "ADD_PAYMENT_SUCCESS":
            return {
                ...newstate,
                ChangePaymentModalStatus: true,
                ChangePaymentStatusLoading: false,
            };

        case "CHANGE_PAYMENT_LOADING":
            return {
                ...newstate,
                ChangePaymentStatusLoading: true,
            };

        case "ADD_PAYMENT_FAIL":
            return {
                ...newstate,
                ChangePaymentModalStatus: false,
                ChangePaymentStatusLoading: false,
            };
        case "CHANGE_ADD_PAYMENT_MODAL_STATE":
            return {
                ...newstate,
                ChangePaymentModalStatus: false,
            };

        case "GET_SALES_COMMISION_DATA":
            if (action.value.rows !== undefined) {
                return {
                    ...newstate,
                    SalesCommisionData: action.value.rows,
                    SalesCommisionPageSize: action.value.pages,
                };
            } else {
                return {
                    ...newstate,
                    SalesCommisionData: action.value,
                };
            }

        case "GET_QUANTITY_DATA":
            return {
                ...newstate,
                ItemQuantitiyData: action.value,
            };

        case "GET_ITEM_LIST_FOR_INVOICE":
            return {
                ...newstate,
                invoiceItemQuantitiyData: action.value,
            };

        case "GET_CREDIT_NOTE_LINES_FOR_INVOICE":
            return {
                ...newstate,
                creditNoteLineData: action.value,
            };
            

        case "GET_QUANTITY_DATA_FOR_OTHER_WORKS":
            return {
                ...newstate,
                ItemQuantitiyDataForOther: action.value,
            };

        case "RESET_QUANTITY_DATA_FOR_OTHER_WORKS":
            return {
                ...newstate,
                ItemQuantitiyDataForOther: null,
            };

        case "GET_QUANTITY_DATA_PO":
            return {
                ...newstate,
                ItemQuantitiyDataPO: action.value,
            };

        case "GET_QUANTITY_DATA_ALL":
            return {
                ...newstate,
                ItemQuantitiyDataALL: action.value,
            };

        case "ADD_BANK_UNSUCCESS":
            return {
                ...newstate,
                addBankData: false,
                loadingatoadddata: false,
            };
        case "ADD_BANK_DATA_LOADING":
            return {
                ...newstate,
                loadingatoadddata: true,
            };
        case "ADD_BANK_SUCCESS":
            return {
                ...newstate,
                addBankData: true,
                loadingatoadddata: false,
            };

        case "CHANGE_BANK_ADDED_STATUS":
            return {
                ...newstate,
                addBankData: false,
            };

        case "GET_BANK_DATA":
            if (action.value.rows !== undefined) {
                return {
                    ...newstate,
                    getbankData: action.value.rows,
                    getbankpageSize: action.value.pages,
                };
            } else {
                return {
                    ...newstate,
                    getbankData: action.value,
                };
            }

        case "UPDATE_BANK_LOADING":
            return {
                ...newstate,
                updateBankDataLoading: true,
            };

        case "UPDATE_BANK_DATA_SUCCESS":
            return {
                ...newstate,
                updatebankdata: true,
                updateBankDataLoading: false,
            };

        case "CHANGE_UPDATE_BANK_DATA_SUCCESS":
            return {
                ...newstate,

                updatebankdata: false,
            };

        case "UPDATE_BANK_DATA_UNSUCCESS":
            return {
                ...newstate,
                updatebankdata: false,
                updateBankDataLoading: false,
            };
        case "BANK_DELETED":
            return {
                ...newstate,
                deletebankstatus: true,
            };
        case "BANK_DELETED_UNSUCCESS":
            return {
                ...newstate,
                deletebankstatus: false,
            };

        case "ADD_BANK_BRANCH_DATA_LOADING":
            return {
                ...newstate,

                loadingBranchData: true,
            };
        case "ADD_BANK_BRANCH_SUCCESS":
            return {
                ...newstate,
                loadingBranchData: false,
                addbankBranch: true,
            };
        case "CHANGE_BANK_BRANCH_ADDED_STATUS":
            return {
                ...newstate,
                addbankBranch: false,
            };

        case "ADD_BANK_BRANCH_UNSUCCESS":
            return {
                ...newstate,
                addbankBranch: false,
                loadingBranchData: false,
            };

        case "GET_BANK_BRANCH_DATA":
            if (action.value.rows !== undefined) {
                return {
                    ...newstate,
                    getbankbranchData: action.value.rows,
                    getbankbranchpageSize: action.value.pages,
                };
            } else {
                return {
                    ...newstate,
                    getbankbranchData: action.value,
                };
            }

        case "GET_BANK_DATA_BRANCH_UNSUCCESS":
            return {
                ...newstate,
                getbankbranchData: false,
            };

        case "UPDATE_BANK_BRANCH_LOADING":
            return {
                ...newstate,

                loadingupdateBranchData: true,
            };
        case "UPDATE_BANK_BRANCH_DATA_SUCCESS":
            return {
                ...newstate,
                loadingupdateBranchData: false,
                updatebankBranch: true,
            };
        case "CHANGE_UPDATE_BANK_BRANCH_DATA_SUCCESS":
            return {
                ...newstate,

                updatebankBranch: false,
            };

        case "UPDATE_BANK_BRANCH_DATA_UNSUCCESS":
            return {
                ...newstate,

                loadingupdateBranchData: false,
                updatebankBranch: false,
            };
        case "BANK_BRANCH_DELETED":
            return {
                ...newstate,

                deleteBankBranchStatus: true,
            };

        case "BANK_BRANCH_DELETED_UNSUCCESS":
            return {
                ...newstate,

                deleteBankBranchStatus: false,
            };

        case "GET_PAMENT_METHODS_DATA_FOR_SUPPLIERS":
            return {
                ...newstate,

                getPayMethodsforSup: action.value,
            };

        case "GET_PAMENT_METHODS_DATA_FOR_SUPPLIERS_UNSUCCESS":
            return {
                ...newstate,

                getPayMethodsforSup: null,
            };

        case "CHANGE_SEQUENCE_MODAL":
            let modelstate = !state.SequenceModalState;
            if (action.value) {
                modelstate = action.value;
            }
            return {
                ...newstate,

                SequenceModalState: modelstate,
            };

        case "GET_PRODUCED_ITEM_IDS_FOR_ORDERLINE":
            return {
                ...newstate,

                ProduceIteIds: action.value,
            };

        case "ADD_ITEM_WEIGHT_PRODUCEDITEMS_DATA":
            return {
                ...newstate,
                itemWeightProduceItems: action.value,
            };

        case "ADD_QUANTITY_DATA":
            return {
                ...newstate,
                ItemQuantitiyData: null,

                QuantityData: [...action.value[0]],

                ProduceIteIds: [],
                countdata: [...action.value[1]],
            };

        case "ADD_QUANTITY_DATA_ONLY":
            return {
                ...newstate,
                QuantityData: [...action.value],

                ProduceIteIds: [],
            };

        case "RESET_QUANTITY_DATA":
            return {
                ...newstate,
                ItemQuantitiyData: null,
                TEMPitemWeightProduceItems: [],
                QuantityData: [],
                itemWeightProduceItems: [],
                ProduceIteIds: [],
                countdata: [],
                ItemQuantitiyDataPO: null,
            };

        case "GET_CUSTOMER_NUMBER":
            return {
                ...newstate,
                getCustomerOrderNumber: action.value,
            };
        case "CHANGE_STATUS_GET_CUSTOMER_NUMBER":
            return {
                ...newstate,
                getCustomerOrderNumber: null,
            };
        case "CAN_NOT_GET_CUSTOMER_NUMBER":
            return {
                ...newstate,
                getCustomerOrderNumber: null,
            };
        case "GET_CUSTOMER_ORDER_DATA":
            if (action.value.rows !== undefined) {
                return {
                    ...newstate,
                    getCustomerOrderData: action.value.rows,
                    getCustomerOrderpageSize: action.value.pages,
                };
            } else {
                return {
                    ...newstate,
                    getCustomerOrderData: action.value,
                };
            }

        case "GET_CUSTOMER_ORDER_DATA_FAILED":
            return {
                ...newstate,
                getCustomerOrderData: null,
            };
        case "CHANGE_INVOICE_REPORT_MODAL":
            return {
                ...newstate,

                InvoiceReportModalState: action.value,
            };

        case "CREATE_CUSTOMER_ORDER":
            return {
                ...newstate,
                createCustomerorstatus: true,
            };
        case "CHANGE_STATUS_CREATE_CUSTOMER_ORDER":
            return {
                ...newstate,

                createCustomerorstatus: false,
            };
        case "CREATE_CUSTOMER_ORDER_FAILED":
            return {
                ...newstate,

                createCustomerorstatus: false,
            };

        case "DELETE_CUSTOMER_ORDER":
            return {
                ...newstate,

                deleteCustomerOrder: true,
            };
        case "DELETE_CUSTOMER_ORDER_UNSUCCESS":
            return {
                ...newstate,

                deleteCustomerOrder: false,
            };

        case "ASSIGN_ITEM_FOR_INVOICE":
            return {
                ...newstate,
                itemAssignModal: action.value,
            };

        case "GET_CUSTOMER_ORDER_LINE_DATA":
            return {
                ...newstate,

                getCustomerorderLineData: action.value,
            };

        case "GET_CUSTOMER_ORDER_LINE_DATA_FAILED":
            return {
                ...newstate,

                getCustomerorderLineData: null,
            };

        case "GET_CUSTOMER_ORDER_DATA_TO_UPDATE":
            return {
                ...newstate,
                customerOrderdataToUpdate: action.value,
            };

        case "PATH_TO_CUSTOMER_ORDER_UPDATE":
            return {
                ...newstate,
                pathToCustomerOrderUpdate: action.value,
            };

        case "LOADING_CUSTOMER_ORDER_UPDATED":
            return {
                ...newstate,

                loadingCustomerOrderUpdate: true,
            };

        case "CUSTOMER_ORDER_UPDATED_SUCCESS":
            return {
                ...newstate,
                loadingCustomerOrderUpdate: false,
                CustomerOrderUpdateStatus: true,
            };

        case "CUSTOMER_ORDER_UPDATED_UNSUCCESS":
            return {
                ...newstate,
                loadingCustomerOrderUpdate: false,
                CustomerOrderUpdateStatus: false,
            };

        case "CUSTOMER_ORDER_UPDATED_SUCCESS_CHANGE":
            return {
                ...newstate,
                CustomerOrderUpdateStatus: false,
            };
        case "ADD_ORDER_STATUS_LOADING":
            return {
                ...newstate,
                loadingOrderStatus: true,
            };

        case "ADD_ORDER_STATUS_SUCCESS":
            return {
                ...newstate,
                loadingOrderStatus: false,
                addOrderStatus: true,
            };

        case "CHANGE_ADD_ORDER_STATUS_SUCCESS":
            return {
                ...newstate,

                addOrderStatus: false,
            };

        case "ADD_ORDER_STATUS_UNSUCCESS":
            return {
                ...newstate,
                loadingOrderStatus: false,
                addOrderStatus: false,
            };

        case "GET_ORDER_STATUS_DATA":
            if (action.value.rows !== undefined) {
                return {
                    ...newstate,
                    getOrderStatusData: action.value.rows,
                    getOrderStatusDatapageSize: action.value.pages,
                };
            } else {
                return {
                    ...newstate,
                    getOrderStatusData: action.value,
                };
            }

        case "LOADING_ORDER_STATUS_DATA_TO_UPDATE":
            return {
                ...newstate,
                loadingOrderStatustoUpdate: true,
            };

        case "ORDER_STATUS_DATA_UPDATED":
            return {
                ...newstate,
                loadingOrderStatustoUpdate: false,
                orderderStatusUpdate: true,
                isDefaultID: null,
            };

        case "CHANGE_ORDER_STATUS_DATA_STATE":
            return {
                ...newstate,

                orderderStatusUpdate: false,
            };

        case "UPDATE_ORDER_STATUS_DATA_UNSUCCESS":
            return {
                ...newstate,
                loadingOrderStatustoUpdate: false,
                orderderStatusUpdate: false,
                isDefaultID: action.value,
            };

        case "Order_STATUS_DATA_DELETED":
            return {
                ...newstate,
                deleteorderStausdata: true,
            };

        case "Order_STATUS_DATA_DELETED_FAILED":
            return {
                ...newstate,
                deleteorderStausdata: false,
            };

        case "CHANGE_DEFAULT_ORDER_STATUS":
            return {
                ...newstate,
                changeDefaultOrderStatus: true,
            };

        case "CHANGE_DEFAULT_ORDER_STATUS_FOR_FALSE":
            return {
                ...newstate,
                changeDefaultOrderStatus: false,
            };

        case "CHANGE_DEFAULT_ORDER_STATUS_FAILED":
            return {
                ...newstate,
                changeDefaultOrderStatus: false,
            };

        case "GET_DEFAULT_ORDER_STATUS":
            return {
                ...newstate,
                getDefaultorderdatastatus: action.value,
            };

        case "GET_DEFAULT_ORDER_STATUS_FAILED":
            return {
                ...newstate,
                getDefaultorderdatastatus: null,
            };

        case "GET_CUSTOMER_ORDER_WITH_QUANTITY":
            return {
                ...newstate,
                getCustomerOrderData: action.value,
            };

        case "CHANGE_INVOICE_UPDATE_BUTTON":
            return {
                ...newstate,
                disableInVoiceUpdateBtn: action.value,
            };
        case "GET_PAYMENT_DETAILS_FOR_INVOICE":
            return {
                ...newstate,
                InvoicePaymentDetails: action.value,
            };
        case "ADD_TEMP_ITEM_WEIGHT_PRODUCED_ITEM_FOR_INVOICE":
            let CheckItemIncluded = state.TEMPitemWeightProduceItems.some((items) => action.value.includes((v) => v.itemId === items.itemId));

            if (!CheckItemIncluded) {
                let currentPItems = state.TEMPitemWeightProduceItems;

                currentPItems.push(...action.value);
                return {
                    ...newstate,
                    TEMPitemWeightProduceItems: currentPItems,
                };
            } else {
                return {
                    ...newstate,
                };
            }

        case "UPDATE_TEMP_ITEM_WEIGHT_P_ITEMS":
            let currentPItems = action.value;
            if (currentPItems && currentPItems.length > 0) {
                // eslint-disable-next-line array-callback-return
                currentPItems.map((weight) => {
                    if (weight.NewState === false && weight.ItemOLDState === false) weight.NewState = true;
                });
            }
            return {
                ...newstate,

                TEMPitemWeightProduceItems: currentPItems,
            };
        case "MANIPULATE_INVOICE_COUNT_DATA":
            return {
                ...newstate,
                UpdateInvoiceCount: !state.UpdateInvoiceCount,
            };

        case "GET_REPORT_PROFIT_LOSS_REPORT":
            return {
                ...newstate,
                ProfitLossItemList: action.value.profitLossItemList,
            };

        case "GET_PROFIT_LOSS_CUSTOMER_DETAIL":
            return {
                ...newstate,
                ProfitLossSelectedItemData: action.value,
            };

        case "GET_CASH_ACCOUNT_DATA":
            return {
                ...newstate,
                getCashAccountData: action.value,
            };

        case "GET_CREDIT_NOTE_LIST":
            return {
                ...newstate,
                getCreditNoteList: action.value,
            };

        case "SET_CN_VIEW_FORM":
            return {
                ...newstate,
                getCreditNoteId:action.value.id,
                getCreditNoteLineList: [],
            };

        case "GET_CREDIT_NOTE_BY_ID":
            return {
                ...newstate,
                getCreditNote: action.value.creditNoteFormData,
                getCreditNoteLineList:action.value.creditNoteLineList,
                getCreditNoteTaxList: action.value.creditNoteTax,
            };

        case "GET_OUTSTANDING_REPORT_DATA":
            return {
                ...newstate,
                outstandingReportData: action.value,
            };

        case "CLEAR_PROFIT_LOSS_CUSTOMER_DETAIL":
            return {
                ...newstate,
                ProfitLossSelectedItemData: [],
            };

        case "GET_RECONCILE_PAYMENTS_DATA":
            return {
                ...newstate,
                getReconcilePaymentData: action.value,
            };
            
        case "GET_CUSTOMER_ORDER_ITEMS":
            return {
                ...newstate,
                getCustomerOrderItems: action.value,
            };

        case "GET_CUSTOMER_ORDER_ITEMS_EXTENDED":
            return {
                ...newstate,
                getCustomerOrderItemsExtended: action.value,
            };

        case "CLEAR_ACCOUNT_DETAIL":
            return {
                ...newstate,
                getAccountsInDetail: [],
            };

        case "GET_ACCOUNTS_DETAIL_PARAMETERS":
            return {
                ...newstate,
                getAccountsInDetailParameters: action.value,
            };

        case "CLEAR_ACCOUNT_DETAIL_PARAMETERS":
            return {
                ...newstate,
                getAccountsInDetailParameters: [],
            };

        case "GET_CUSTOMER_BY_ID":
            return {
                ...newstate,
                getCustomerById: action.value
            };

        case "GET_ITEM_QTY_FOR_DATE":
            return {
                ...newstate,
                itemQtyForDate: action.value
            }

        case "GET_OPEN_CUSTOMER_ORDER_ITEMS":
            return {
                ...newstate,
                openCustomerOrderItems: action.value
            }
         case "GET_OVERVIEW_CODE_DATA_LIST":
            return {
                ...newstate,
                getOverviewCodeData: action.value,
            };

        case "GET_OVERVIEW_CODE_LAST_VALUE":
            return {
                ...newstate,
                getOverviewCodeLastValue: action.value,
            };

        case "GET_OVERVIEW_CODE_DATA_LIST_DETAIL_WITH_PARAMS":
            return {
                ...newstate,
                getOverviewCodeDataDetailWithParams: action.value,
            };
    
        case "ADD_OVERVIEW_CODE_UNSUCCESS":
            return {
                ...newstate,
                OverviewCodeAddStatus: false,
                OverviewCodeLoadingStatus: false,
            };

        case "ADD_OVERVIEW_CODE_LOADING":
            return {
                ...newstate,
                OverviewCodeLoadingStatus: true,
            };

        case "ADD_OVERVIEW_CODE_SUCCESS":
            return {
                ...newstate,
                OverviewCodeAddStatus: true,
                OverviewCodeLoadingStatus: false,
            };

        case "CHANGE_OVERVIEW_CODE_STATUS":
            return {
                ...newstate,
                OverviewCodeAddStatus: false,
            };
                
        case "UPDATE_OVERVIEW_CODE_LOADING":
            return {
                ...newstate,
                loadingUpdateOverviewCode: true,
            };   

        case "OVERVIEW_CODE_UPDATED":
            return {
                ...newstate,
                OverviewCodeUpdateStatus: true,
                loadingUpdateOverviewCode: false,
            };
                
        case "CHANGE_OVERVIEW_CODE_UPDATE_STATE":
            return {
                ...newstate,
                OverviewCodeUpdateStatus: false,
            }; 

        case "UPDATE_OVERVIEW_CODE_UNSUCCESS":
            return {
                ...newstate,
                loadingUpdateOverviewCode: false,
                OverviewCodeUpdateStatus: false,
            };    
        case "OVERVIEW_CODE_DELETED":
            return {
                ...newstate,
                deleteOverviewCodestatus: true,
            };
        case "OVERVIEW_CHILD_CODE_DELETED":
            return {
                ...newstate,
                deleteChildOverviewCodestatus: true,
            };
        case "OVERVIEW_CODE_DELETED_UNSUCCESS":
            return {
                ...newstate,
                deleteOverviewCodestatus: false,
            };
        case "OVERVIEW_CHILD_CODE_DELETED_UNSUCCESS":
            return {
                ...newstate,
                deleteChildOverviewCodestatus: false,
            };
        case "CREATE_PARTIAL_CUSTOMER":
            return {
                ...newstate,
                createPartialCustomerStatus: true,
            }
        case "CREATE_PARTIAL_CUSTOMER_FAILED":
            return {
                ...newstate,
                createPartialCustomerStatus: false,
            }
        case "GET_INVOICE_ORDER_ITEM_IN_DETAIL":
            return {
                ...newstate,
                getInvoiceOrdersInDetail: action.value
            };
        case "GET_INVOICE_ORDER_ITEM_SUMMERY_IN_DETAIL":
            return {
                ...newstate,
                getInvoiceOrdersInSummeryDetail: action.value,         
            };
        case "GET_INVOICE_OVERVIEW_DATA_REPORT":
            return {
                ...newstate,
                getInvoiceOverviewDetail: action.value,         
            };   
        case "UPLOAD_PAYMENT_UNSUCCESS":
            return {
                ...newstate,
                paymentUploadStatus: false,
            };

        case "UPLOAD_PAYMENT_SUCCESS":
            return {
                ...newstate,
                paymentUploadStatus: true,
            };
        
        case "PROFORMA_INVOICE_REPORT_MODAL":
            return {
                ...newstate,
                ProformaInvoiceReportModalState: action.value,
            };

        case "GET_PROFORMA_INVOICE_PRINT_DATA":
            return {
                ...newstate,
                ProformaInvoicePrintData: action.value,
            };

        case "GET_PAYEMENT_METHODS":
            return {
                ...newstate,
                PayementMethods: action.value,
         };

         case "INVOICE_UPLOAD_IMAGE_LOADING":
            return {
                ...newstate,
                invoiceImageUpdateState: true,
            };
        
        case "INVOICE_UPLOAD_IMAGE_SUCCES":
            return {
                ...newstate,
                invoiceImageUpdateState: false,
            };

        case "INVOICE_UPLOAD_IMAGE_FAIL":
            return {
                ...newstate,
                invoiceImageUpdateState: false,
            };

        case "GET_EXPENCE_ACCOUNT_DATA":
            return {
                ...newstate,
                geteExpenceAccountdataAccounts: action.value,
            };

        case "GET_STATUS_REPORT_DETAIL":
            return {
                ...newstate,
                getStatusReportInDetail: action.value,
            };

        default:
            break;
    }
    return newstate;
};
export default reducer;
